"use client";

import { Footer } from "@/components/Footer";
import { Network } from "@/components/Wallet/Network";
import { useLingui } from "@lingui/react";
import { AppShell, Button, Container, Flex, Group, NumberFormatter, Text, rem } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import Image from "next/image";
import Link from "next/link";
import type { PropsWithChildren } from "react";
import { PartnerSlide } from "../Partners/PartnerSlide";
export const HomeLayout: React.FC<PropsWithChildren> = ({
  children
}) => {
  const pinned = useHeadroom({
    fixedAt: 120
  });
  const {
    i18n
  } = useLingui();
  return <AppShell header={{
    height: 80,
    collapsed: !pinned,
    offset: false
  }} padding="md">
			<AppShell.Header withBorder={false} bg="gray.20" px={{
      lg: "md"
    }}>
				<Container mx="auto" h="100%" fluid>
					<Group h="100%" align="center" justify="space-between">
						<Flex component={Link} href="/">
							<Image src="/assets/images/logo.svg" width={102} height={32} alt="Logo" />
						</Flex>
						<Group align="center" mr="auto" visibleFrom="lg" ml={20} style={{
            display: "none"
          }}>
							<Group gap={5}>
								<Text size="md" c="white" fw={600}>
									{i18n._("TVL:")}
								</Text>
								<Text c="purple.5" fw={600}>
									<NumberFormatter prefix="$" value={100000000} thousandSeparator="," decimalSeparator="." />
								</Text>
							</Group>
							<Group gap={5}>
								<Text size="md" c="white" fw={600}>
									{i18n._("APR:")}
								</Text>
								<Text c="purple.5" fw={600}>
									<NumberFormatter prefix="+" suffix="%" value={200} thousandSeparator="," decimalSeparator="." />
								</Text>
							</Group>
						</Group>
						<Flex align="center" gap={10}>
							<Network />
							{/* <ThemeSwitcher size="input-lg" /> */}
							<Button component={Link} href="/swap" color="purple.5" size="lg" miw={200} visibleFrom="md" disabled style={{
              pointerEvents: "none"
            }}>
								{i18n._("Launch App")}
							</Button>
						</Flex>
					</Group>
				</Container>
			</AppShell.Header>

			<AppShell.Main pt={`calc(${rem(80)} + var(--mantine-spacing-md))`} pb={200} pos="relative" px={0}>
				{children}
			</AppShell.Main>
			<PartnerSlide />
			<AppShell.Footer p="md" withBorder={false} bg="gray.20" px={{
      sm: "lg"
    }}>
				<Container mx="auto" h="100%" fluid>
					<Footer />
				</Container>
			</AppShell.Footer>
		</AppShell>;
};